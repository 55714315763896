export const ValidationMessage={
    toasterClass:{
        success:'success',
        error:'error',
        warning:'warning',
    },
    messageImage:{
        success:'assets/images/success.png',
        error:'assets/images/error.png',
        warning:'assets/images/warning.png',
    },
    messageClass:{
        success:'successMsgAlert',
        error:'errorMsgAlert',
        warning:'warningMsgAlert',
    },
    common :{
        applicationError:'Unexpected error occured, Kindly contact administrator.',
        dirtyFormError:'There are some unsaved changes pending. Do you want to save or data will lost?',
        addedSuccessMsg:'Data Added Successfully',
        savedSuccessMsg:'Data Saved Successfully',
        mandatoryFieldsMsg: 'Please fill each mandatory fields',
        savedFailedMsg:'Failed to save data',
        updateSuccessMsg:'Data Updated Successfully',
        updateFailedMsg:'Failed to update data',
        deleteSuccessMsg:'Data deleted Successfully',
        deleteFailedMsg:'Failed to delete data',
        formError: 'Please check all form fields',
        formMandatoryError: 'Please fill all mandatory form fields',
        dataAlreadyExistMsg: 'Transaction for provided month-year already exists',
        nameExistsMsg: 'Bank details for provided name-account already exists',
        noFormChange:'No changes to save',
        selectApplication:'Please select applications to update the status.',
        dateError: 'Invalid Date',
        emailError : 'Email must be a valid email address',
        invalidData: 'Invalid value',
        minCharacterError: 'Minimum character required is',
        maxCharacterError: 'Maximum characters allowed are',
        minValueError: 'Minimum value is',
        maxValueError: 'Maximum value is',
        requireError: 'This is required',
        nameLastNameError:'Name must contain both first and last name',
        unexpectedError: 'Unpexpected error occured, Please try again',
        saveConfirmation:'There are some unsaved changes pending. Do you want to save?',
        deleteCOnfirmation: 'Record will be deleted, want to continue?',
        copyConfirmed: 'Record will be deactivated, want to continue?',
        waiverRequestConfirmation:'Are you sure you want to raise the waiver request with this much percentage?',
        LMSConfirmation: "Are you sure you want to push this case to LMS?",
        noRecordsFound: 'No Records Found',
        dataAlreadyExist:'Data already exist in table',
        UPDATE_PERMISSION: 'Permission updated successfully',
        passwordFormat: 'Password must be at least 8 characters long with one uppercase, one lowercase, one numeric & one special character.',
        mismatchpassowrd: 'Passwords do not match',
        differentPassword:'Password and Confirm Password does not match.',
        ID_PROOF_REQUIRED: 'Atleast one ID Proof is required.',
        GENERATE_REPORT: 'Report Generated Successfully.',
        oneFieldRequied: 'Atleast one field is required.',
        latitudeError:'Invalid latitude',
        longitudeError:'Invalid longitude',
        OTP_ERROR: "Please enter valid OTP.",
        NoFileUploaded:"No document",
        pushToLMS: "Case is pushed to LMS.",
        updateLMS: "Case is updated to LMS.",
        SPECIAL_CHAR_NOT_ALLOWED: "Given special character in input is not allowed.",
        REMARK_MIN_LENGHT: "Remark must be at least 30 characters long.",
        searchErrorMsg: "Please Select Assigned Branch & Department.",
        fileDownloadingConf: "Do you want to download ?",
        minCharacter50: "Min. 50 characters",
        minCharacter5: "Min. 5 characters",
        minCharacter4: "Min. 4 characters",
        saveConfirm: "Data will be saved, want to continue?",
        mailSuccess: 'Email sent Successfully',
        msgSuccess: 'SMS sent Successfully',
        approvalRightsValue:'Minimum Value should be less than maximum value',
        confirmResetMessage:'are you sure to reset data?',
        confirmUploadMessage:'are you sure to upload data?',
        OTPSentSuccess:'OTP sent successfully',
        fileNameError:'Invalid file name',
        processConfirmation:'Are you sure you want to process this batch?',
        inValidPincode: 'Pincode Not Found',
        verifyMobileNumber:'Please verify mobile number',
        backToMain:'Are you sure you want to go back?',
    },
    login:{
        usernameRequired:'Username is required',
        passwordRequired:'Password is required',
        companyRequired:'Company is required',
        invalidPassword:'invalid username or password ',
        meetingpasscode: 'Meeting Code is Required',
        invalidLogin: 'Unauthorised Access',
    },
    loanInformation:{
        tvrValidationError:'Invalid Flag',
        minTenureError:'Minimum value ',
        maxTenureError:'Maximum value ',
        minLoanError:'Minimum value ',
        maxLoanError:'Maximum value ',
        tvrError:'Kindly select all TVR Status.',
        emiError:'Affordable EMI should be less than loan amount requested',
        sameAppNumErr:'Invalid refered application number',
        processingFeeErr:'Processing fee 0 defined',
    },
    roiWaiver:{
        minWaiverError:'Minimum value ',
        maxWaiverError:'Maximum value ',
    },
    loanInsurance: {
        requireError: 'This is required.',
        nameError: 'Characters limit is between 2 - 100',
        onlyNumberError: 'Only numbers allowed',
        numberWithDecimeal: 'Number upto to decimal allower',
        addressError: 'Characters limit is between 5 - 500',
        landmarkError: 'Characters limit is between 5 - 200',
        pinCodeError: 'Please enter 6 digits',
        premiumError: 'Sum assured and Premium cannot be zero, if not required then delete the insurance',
        nullPremiumError : 'Please calculate premium'
    },
    personalDetails:{
        stayInError:'Total stay should be less than age',
        matDatepickerMin:'date must be greater than issue date',
        panError:'Invalid PAN Number',
        uidError:'Invalid UID Number',
        voterError:'Invalid Voter ID',
        passportError:'Invalid Passport Number',
        DLError:'Invalid Driving Licence',
        numberAlreadyVerified:'Mobile number already verified',
        identificationDefaultError:'Invalid identification number',
        duplicateKYCcMsg:'Identification type already added',
        KYCBtnAddTxt:'Add KYC',
        KYCBtnUpdateTxt:'Update KYC',
        mobAlreadyVerified:'Mobile number already verified',
        lastNameRequired:'Required for Bureau',
        uidGetError:'UID not found',
    },
    propertyDetails:{
        minCashOcr:'Cash ocr value should be less than ',
        maxOcr:'Ocr value should be greator than ',
    },
    dedupe:{
        requireAllStatus:'kindly update all dedupe status',
        duplicateApplication:'application number already linked. can not assign same application anumber again'
    },
    emplDetails:{
        requireerror : 'This is required',
        minLenghtError : 'Length at least 2 characters',
        maxLengthError : 'Character must be less than 30 characters',
        gstPatternError : 'Please enter in pattern like (00XXXXX0000X0ZX)',
        businessVintageYr : 'Max number of years is 999',
        AddressError : 'Adress must be in limits between 10-500 characters',
        landmarkError : 'Landmark must be in 5 -200 characters',
        pinError : 'Must be 6 digits long',
        dropdownError : 'Please select one option',
        contactNoError : 'Invalid Contact No.',
        designationError : 'Characters must in limit between 2-20 characters',
        employeeIdError : 'Characters must in limit between 2-10 characters',
        emailerror : 'Email must be a valid email address',
        enterOnlyNumberError : 'Only number are allowed',
        decimalNumberError : 'Only Numbers & Upto 2 decimal digit allowed'
    },
    liabilityAndAssets:{
        nineMonthBouncesError: 'Bounces count is less than 6 month value.',
        cardUtilizationError: 'Utilization value should not be greater than credit limit.',
        B_TENOR_VALUE_ERROR: 'Balance tenor value not greater than tenor value.'
    },
    loginFee:{
        waveError:'Total waiver can not be greater than total due',
        transferValid:'Transfer Amount must be less than or equal to Total Due'

    },
    documentUpload:{
        documentTypeErr: 'Document type is required.',
        documentNameErr: 'Document name is required.',
        documentUploadErr: 'Document is required.',
        supportedDocumentErrMsg: 'Only images, pdf and zip are supported.',
        documentNotFound: 'Document Not Found.',
        DOC_UPLOADED: 'Uploaded Successfully.',
        DOC_CODE_ERR:'Document Code is required.'
    },
    income:{
        netIncomeError:'income can not be -ve'
    },
    bankDeatils:{
        invalidYear:'Invalid transection period',
        transactionListErr: 'In case of income type "Bank Salary" or Salary Credit "Yes", then "Six month transaction is allowed for Bank Salaried profile"',
        senpListErr: 'Twelve  month transactions are allowed for non Bank Salaried profile.',
        transectionDataEror: 'kindly validate transections data too for errors',
    },
    ROLE_PERMISSION:{
        ADDED_ROLE: 'Role Added Successfully.',
        UPDATED_ROLE: 'Role Updated Successfully.',
        DELETED_ROLE: 'Role Deleted Successfully.',
        COMPONENT_PERMISSION_MSG: 'Component Permission Added Successfully.'
    },
    technical:{
        noSubsequentErr:'No subsequent data present',
        technicaldDocumentErrMsg: 'Invalid file',
        technicaldDocumentUnMatchMsg: 'Application number not matched in file',
    },
    masterEmployee:{
        employeeCodeUnique:'Emplyee code must be unique'
    },
    loanProcessing:{
        disbursalAmountLimitError:'Disbursal Amount should be less than Sanctioned Amount',
        favouringDisbursedError:'Favouring is already Disbursed or Cancelled',
        dltvRequired:'Please fill all rate fields',
        dirtyError: 'Please recalculate DLTV.',
        maxCharInCheque:'Combined length of Favouring Detail and Account number should be less than 80 characters'
    },
    paymentStatusUpdate:{
        rowUnmatchError:'Excel Data row not match with application data'
    },
    fileTrackStatusUpdate:{
        rowUnmatchError:'Excel Data row not match with application data'
    },
    chargeWaiver:{
        totalWaiverError:'Total waiver should be same'
    },
    borrowerRefund:{
        totalRefundError:'Total refund should be same',
        borrowerRefundFavouring:'Please add borrower refund favouring details'
    },
    loanReschedulement:{
        tableErr:'can not save with empty payment data'
    },
    leadManagement:{
        viewError:'Can only view online lead data'
    },
    loanLetter:{
        viewError:'Letters could generate only for Sanctioned/Disbursed/Disbursal Cancelled cases'
    },
    lettersKit:{
       viewError :'Configuration of Loan Agreement Letters is missing kindly contact to Operation team'
    },
    BatchGeneration:{
        chequeExpire:'Cheque request expiry date reached',
        neftExpire:'Request expiry date reached'
    },
    loanAgreement:{
        favAmtError:'Total favouring amount can not be greater than saction amount',
    },
    leegality:{
        docTypeError:'Please select document type first',
        signRequestError:'Please select document type and employee first',
        docPrepareMsg:'Document preparation is in progress. Please check after 10 minute',
        docPrepareConfirm:'Document will be prepared, want to continue?',
        docPreparedAlready:'Document already prepared, want to continue?',
        docApprovedAlready:'Document already approved , want to continue?',
        docPrepareInprogressConfirm:'Document preparation is in progress , want to continue?',
    },
    sourceFunding:{
        dateValidationError:'Invalid Month',
        batchYearMendetoryError:'Please select year first',
    },
    draftDSA:{
        addressOptional:'Office address or Residence address is required',
        uploadOptional:'Office address or Residence address proff is required',
    },
    ocr:{
        ocrSelectErr:'Select OCR payment first',
    },
    legalDoc:{
        duplicateFile:'File with same name is already uploaded',
        noExcelData:'No File chosen to upload',
        saveLossMsg:'your previous saved data will be lost, want to continue?',
        redirctDirtyFormError:'There are some unsaved changes pending. Do you want to proceed ?',
    },
    dcb:{
        monthSelectErr:'Select year first',
    },
    depositSlip:{
        approveDeposit:'Are you sure you want to approve the Deposit Slip  If clicked Ok , Deposit slip will be Approved',
       
    }
  };
