import { Injectable } from '@angular/core';
import { Router } from '@angular/router'; 
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { UtilityService } from "src/app/services/utility.service";

import { APIConstant } from "src/app/constants/apiConstants";
import { environment } from "src/environments/environment";


import Swal from 'sweetalert2'
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private url = environment.apiUrl;

  constructor(
    private utilityService: UtilityService,
    private http: HttpClient,
    private router: Router
  ) { }

  getRegionList(){
    const t = new Date().getTime();
    let apiURL=APIConstant.REGIONLIST+'?_x='+t;
    return this.http.get<any>(`${this.url}${apiURL}`,{});
  }
  getBranchList(){
    const t = new Date().getTime();
    let apiURL=APIConstant.BRANCHLIST+'?_x='+t;
    return this.http.get<any>(`${this.url}${apiURL}`,{});
  }
  getPincodeData(pincode){
    const t = new Date().getTime();
    let apiURL=APIConstant.PINCODE + pincode+'?_x='+t;
    return this.http.get<any>(`${this.url}${apiURL}`,{});
  }
  getImages(imageID){
    const t = new Date().getTime();
    let apiURL=APIConstant.GET_DOCUMENT_LIST_OTHER + imageID+'?_x='+t;
    return this.http.get<any>(`${this.url}${apiURL}`,{});
  }
  getUploadList(imageID){
    const t = new Date().getTime();
    let apiURL=APIConstant.GET_DOCUMENT_LIST_BY_NAME + imageID+'?_x='+t;
    return this.http.get<any>(`${this.url}${apiURL}`,{});
  }
  getUID(uid){
    const t = new Date().getTime();
    let apiURL=APIConstant.GET_UID + uid+'?_x='+t;
    return this.http.get<any>(`${this.url}${apiURL}`,{});
  }
  getLoanProductData(){
    const t = new Date().getTime();
    let apiURL=APIConstant.LOANPRODUCT;
    let paramData={
      '_x':t
    };
    return this.http.get<any>(`${this.url}${apiURL}`,{params: paramData});    
  }
  getMasterData(){
    const t = new Date().getTime();
    let apiURL=APIConstant.ALLMASTERS;
    let paramData={
      '_x':t
    };
    return this.http.get<any>(`${this.url}${apiURL}`,{params: paramData}); 
  }
  getDocumentCode(){
    const t = new Date().getTime();
    let apiURL=APIConstant.DOCUMENT_AND_TYPE_LIST;
    let paramData={
      '_x':t
    };
    return this.http.get<any>(`${this.url}${apiURL}`,{params: paramData}); 
  }
  getDocumentType(paramData,apiEndPoint){
    const t = new Date().getTime();
    let apiURL=apiEndPoint+'/document/';
    paramData['_x']=t;
    return this.http.get<any>(`${this.url}${apiURL}`,{params: paramData}); 
  }

}
