import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskPipe'
})
export class MaskPipe implements PipeTransform {

  transform(value: string, type: 'pan' | 'aadhar' | 'mobile' | 'email' | 'bank' | 'creditCard'): string {
    if (!value) {
      return value;
    }
    switch (type) {
      case 'pan':
        return this.maskPAN(value);
      case 'aadhar':
        return this.maskAadhar(value);
      case 'mobile':
        return this.maskMobile(value);
      case 'email':
        return this.maskEmail(value);
      case 'bank':
        return this.maskBankAccount(value);
      case 'creditCard':
        return this.maskCreditCard(value);
      default:
        return value;
    }
  }
  private maskPAN(pan: string): string {
    return pan.replace(/.(?=.{4})/g, '*');
  }
  private maskAadhar(aadhar: string): string {
    return aadhar.replace(/^(.{4})(.{4})(.{4})$/, '**** **** $2');
  }
  private maskMobile(mobile: string): string {
    return mobile.replace(/^(\d{2})(\d+)(\d{2})$/, '$1****$3');
  }
  private maskEmail(email: string): string {
    const [localPart, domain] = email.split('@');

    const maskedLocal = '*'.repeat(localPart.length);

    return `${maskedLocal}@${domain}`;
  }
  private maskBankAccount(account: string): string {
    return account.replace(/.(?=.{4})/g, '*');
  }
  private maskCreditCard(card: string): string {
    return card.replace(/\d(?=\d{4})/g, '*').replace(/(\*{4})(?=\d)/g, '$1 ');
  }
}
