import { Pipe, PipeTransform } from '@angular/core';
import { IndianCurrencyPipe } from './indian-currency.pipe'; // Import your existing pipe

@Pipe({
  name: 'integerPart'
})
export class IntegerPartPipe implements PipeTransform {
  constructor(private indianCurrencyPipe: IndianCurrencyPipe) {}

  transform(value: string): string {
    if (!value) {
      return '₹0';
    }

    // Parse the integer part only
    const integerValue = Math.floor(parseFloat(value)).toString();

    // Use the existing IndianCurrency pipe for formatting the integer part
    return this.indianCurrencyPipe.transform(integerValue, 0); // Pass 0 for fractionSize
  }
}
